@import '../../styles/brand-colors';
.no-data-alert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: $dusk-lightest;

    span {
        line-height: 24px;

        padding: 0;
    }

    .no-data-alert-icon {
        margin-right: 8px;

        > path {
            fill: $dusk-lightest;
        }
    }
}
