@import '../../styles/brand-colors';
.validation-error {
    border: 1px solid red;
    border-radius: 4px 4px;
}
.min-max-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $midnight-20;
    height: 100%;

    div {
        height: 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .min-max-selector-label {
        font-weight: bold;
        margin-right: 8px;
        height: 100%;
    }

    .min-max-selector-input {
        height: 100%;

        span {
            font-size: 14px;
        }

        input {
            width: 97px;
        }

        margin-bottom: 16px;
        margin-right: 17px;
    }
}
