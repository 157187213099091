@import '../../styles/brand-colors';

.table-container {
    border: 1px solid $midnight-30;
    border-radius: 4px;
    margin-top: 3px;
    width: 200px;
    position: relative;
    height: 120px;
    white-space: nowrap;
    overflow-y: scroll;

    .option {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        border-bottom: 1px solid #b2c2c8;

        .value {
            width: 80px;
        }

        .display {
            font-weight: bold;
            min-width: 50px;
            margin-left: 5px;
            text-align: right;
        }
    }
}

.table-container > table > tr:last-child {
    border-bottom: none;
}
