@import '../../styles/brand-colors';

.units-table {
    border: 1px solid $midnight-30;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;
    .virtualized-table-header.filtered-action-header {
        color: #4c5357 !important;
        margin-left: 5px;
    }
}
.unit-table-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: 1px solid $dusk-lightest;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px;
    align-items: center;
}
.unit-table-header-container {
    position: relative;
}

.disabled {
    pointer-events: none;

    > path {
        fill: $dusk-twenty;
    }
}
