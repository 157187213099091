@import '../../styles/brand-colors';

.save-side-bar {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px solid $dusk-thirty;

    box-shadow: -2px 1px 3px $dusk-thirty;
    border-radius: 0 3px 3px 0;
    display: flex;
    flex-direction: column;

    top: -8px;
    right: 0;
    height: calc(100% + 8px);
    width: 25%;
    z-index: 200;
}

.save-side-bar-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;
    padding: 17px;
    margin-bottom: 8px;

    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}

.save-side-bar-options {
    flex-grow: 1;
}

.save-side-bar-summary {
    display: flex;
    overflow-y: auto;
    min-height: 0;
    flex-grow: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
}

.save-side-bar-step {
    display: flex;
    flex-direction: column;
    min-height: 0;

    p {
        margin: 0;
        padding: 0 0 0 16px;
        color: $dusk-lighter;
        font-size: 16px;
    }
}

.step-separator {
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 16px;
}

.save-side-bar-value {
    margin-left: 27px;
    margin-right: 27px;

    & input,
    Textarea {
        color: $dusk-lighter;
        border: 1px solid $dusk-thirty;
        border-radius: 5px 5px;
        width: 100%;

        &:focus {
            outline: $midnight-30;
            border: 2px solid $midnight-30;
        }

        &.error {
            border-color: $danger;
        }
    }

    & input {
        text-align: center;
    }

    & textarea {
        resize: none;
    }

    &.select-input {
        margin-right: 22px;
    }
}

.save-side-bar-input-group {
    margin-right: 16px;
    margin-bottom: 16px;

    & .save-side-bar-input-label {
        margin-left: 27px;
        margin-bottom: 5px;
        font-weight: bold;
        color: $dusk-lighter;
    }

    & .save-side-bar-input-note {
        margin-left: 27px;
        font-size: 10px;
        font-style: italic;

        & .bold {
            font-weight: bold;
        }

        & .danger-text {
            color: $danger;
        }
    }
}

.save-side-bar-radio-group-label {
    color: $dusk-light;
    font-weight: bolder;
    font-size: 14px;
}

.save-to-period-options {
    margin-left: 27px;
    margin-right: 27px;
    margin-bottom: 8px;

    .save-to-period-date-range-picker {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        > div {
            width: 100%;
        }

        .MuiInputBase-input {
            height: inherit;
        }

        .period-start-date-picker {
            margin-right: 8px;
        }
    }

    .period-dow-filter-select {
        margin-top: 8px;
    }
}

.save-side-bar-apply-button {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    border-top: 1px solid $dusk-thirty;

    & button {
        float: right;
        margin-top: 8px;
        padding-right: 24px;
        padding-left: 24px;

        @media screen and (max-width: 1500px) {
            width: 100%;
        }
    }
}
