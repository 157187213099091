.date-range-pickers {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;

    > div {
        width: 100%;
        &:nth-child(1) {
            margin-right: 8px;
        }
    }
}
