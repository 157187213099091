@import '../../styles/brand-colors';

.criteria-selector {
    padding: 32px;
    border: 1px solid $midnight-30;
    border-radius: 4px;
    min-height: 500px;
    height: 100%;

    .criteria-selector-top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .refresh-criteria-icon {
            margin-left: auto;
        }
    }

    .menu-selectors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        > div {
            flex-grow: 1;
            width: 289px;
            height: 231px;
            margin-right: 50px;

            &:last-child {
                margin-right: 0;
            }
        }

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div {
                width: 100%;

                &:after {
                    content: '';
                    top: 5px;
                    bottom: 0;
                    right: 0;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    border-bottom: 1px solid $dusk-lightest;
                }
                &:last-child:after {
                    display: none;
                }
            }
        }
    }

    .min-max-selectors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        > div {
            margin-top: 16px;
            flex-grow: 1;
            min-width: 407px;
            height: 114px;
            margin-right: 50px;

            &:last-child {
                margin-right: 0;
            }
        }

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div {
                width: 100%;
            }
        }
    }
}
