@import '../../styles/brand-colors';

.tooltip-top {
    margin-top: 2px;
    padding: 5px 0;
    width: 100%;
}

.tooltip-body {
    margin-top: 20px;
    padding: 5px 10px;
    width: max-content;
    max-width: 340px;
}

.tooltip-bottom {
    padding: 0 20px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.tooltip-bottom-left {
    width: 50%;
    flex: 1;
}

.tooltip-bottom-right {
    width: 50%;
    flex: 1;
}

.close-button {
    float: right;
    width: max-content;
    cursor: pointer;
}

div[id$='-column-tooltip'].MuiTooltip-popper {
    width: fit-content;
    z-index: inherit;
    pointer-events: all !important;
}

div[id$='-column-tooltip'].MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 14px;
    color: $black;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: fit-content;
    max-width: 360px;
}

.tooltip-icon {
    margin-left: 5px;
    cursor: pointer;
}
