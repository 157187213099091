@import '../../styles/brand-colors';

.menu-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu-selector-label {
    font-weight: bolder;
    margin-bottom: 4px;
}

.menu-selector-search {
    margin-bottom: 8px;
    width: 100%;
}

.menu-selector-options {
    border: 1px solid $midnight-30;
    border-radius: 4px;
    display: block;
    overflow-y: auto;
    margin-block-end: 8px;
    height: inherit;
    width: 100%;

    .menu-selector-item {
        background-color: $white;
        width: 100%;
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;

        span {
            padding-left: 15px;
        }
    }

    .selected {
        background-color: $midnight-10 !important;
    }
}
