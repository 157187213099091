@import '../../styles/brand-colors';

.cohort-factor-view {
    min-height: 800px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.cohort-factor-top-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid $dusk-thirty;

    h5 {
        font-size: 20px;
        margin-left: 5px;
    }

    svg {
        margin-left: 8px;
        margin-right: 8px;

        & > path {
            fill: $dusk-lightest;
        }
    }
}

.unit-cohort-factor-body {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .cohort-factor-alert {
        flex-grow: 1;
        > div {
            float: none;
            width: inherit;
        }
    }
}

.unit-cohort-factor-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .factor-bar-chart {
        flex-grow: 1;
        width: 70%;
    }
}
