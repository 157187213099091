@import '../../styles/brand-colors';

.content-panel-configuration {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    border: 1px solid $dusk-thirty;
    border-radius: 4px;
    padding: 12px 16px 12px;

    .error-container {
        display: flex;

        div {
            margin: 0 0 12px 0;
        }
    }
}

.context-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.refresh-criteria-icon {
    margin-top: 5px;
}

.divided-row {
    display: flex;
    flex-direction: row;

    & > div {
        position: relative;
        padding-right: 32px;
        padding-left: 32px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &:after {
            content: '';
            top: 5px;
            bottom: 0;
            right: 0;
            position: absolute;
            border-right: 1px solid $gray-five;
        }

        &:last-child:after {
            display: none;
        }
    }
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.content-panel-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .content-panel-configuration-title {
        color: $midnight-70;
        font-weight: 900;
        font-size: 16px;
        margin: 6px;
    }

    .date-refresh-icon {
        & > path {
            fill: $dusk-light;
        }
    }
}

.content-panel-details {
    color: $midnight-70;
    display: flex;
    flex-direction: column;

    > div {
        margin-top: 6px;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
    }
}

.date-range-selector {
    flex-grow: 1;

    > div {
        &:nth-child(1) {
            margin-right: 4px;
            flex-grow: 1;
            width: 66.6%;
        }
        &:nth-child(2) {
            margin-left: 4px;
            flex-grow: 1;
            width: 33.4%;
        }
    }

    .date-picker {
        & .MuiFormControl-root {
            display: contents;
        }

        & .MuiInputBase-input {
            width: 80%;
        }

        & .MuiPickersBasePicker-pickerView {
            position: fixed;
        }

        & .MuiInputAdornment-root {
            width: 20%;

            button {
                display: contents;
            }
        }
    }
}

.date-range-filters {
    flex-direction: row;
    flex-grow: 1;

    & > div {
        flex-grow: 1;
        &:first-child {
            margin-right: 16px;
        }
    }
}

.date-minrate-filters {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    & > div {
        width: 100%;
        margin: 8px;
    }
}

.date-period > div {
    margin-right: 8px;
    min-width: 100px;
}

.link-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & span:nth-child(-n + 3) {
        border-right: 1px solid #ccd6db;
        padding-right: 10px;
    }
}

.external-link {
    vertical-align: top;
    & > path {
        fill: $dusk-lighter;
    }
}

.holiday-input {
    min-width: 130px !important;
}

@media screen and (max-width: 1600px) {
    .input-group {
        flex-direction: column;

        & > div {
            width: 100%;
        }
    }

    .date-range-filters {
        & > div {
            margin-bottom: 8px;

            &:nth-child(1) {
                margin-right: 0;
            }

            &:nth-child(2) {
                margin-right: 0;
            }

            &:nth-child(3) {
                margin-right: 0;
            }
        }

        .date-period > div {
            margin-right: 0;
            margin-bottom: 8px;
        }

        .link-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & span:nth-child(-n + 3) {
                border-right: 1px solid #ccd6db;
                padding-right: 10px;
            }
        }
    }

    .date-range-selector {
        & > div {
            &:nth-child(1) {
                margin-right: 0;
                width: 100%;
            }
            &:nth-child(2) {
                margin-top: 8px;
                margin-right: 0;
                width: 100%;
            }
        }
    }

    .date-period > div {
        margin-right: 0;
        margin-bottom: 8px;
    }

    .input-days {
        margin-right: 16px;
    }
}

@media screen and (max-width: 1080px) {
    .divided-row {
        flex-direction: column;

        & > div {
            padding: 16px 0 32px;

            &:first-child {
                padding-top: 0;
            }

            &:after {
                right: 5px;
                left: 5px;
                border-right: none;
                border-bottom: 1px solid $gray-five;
                z-index: -10000;
            }
        }
    }

    .date-period > div {
        margin-right: 0;
    }

    .date-range-selector,
    .date-range-filters {
        width: 100%;
    }
    .link-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        & span:nth-child(-n + 3) {
            border-right: 1px solid #ccd6db;
        }
        & span {
            padding-left: 5px;
        }
    }
}

.apply-filters-button {
    //padding-top: 56px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    & button {
        width: 220px;
        height: 28px;
        margin: 8px;
        .search-icon-position {
            margin-top: -8px;
            justify-content: center;
            align-items: center;
            & svg {
                margin-left: 16px;
            }
        }
    }
}

.input-filter {
    padding: 32px;
}
