.draggable-list {
}

.draggable-list-item {
    display: flex;
    flex-direction: row;

    &.dragging {
        opacity: 0.5;
    }
}
