@import '../../styles/brand-colors';

.grid {
    display: grid;
    grid-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: auto;
    height: 100%;
}
.item {
    display: flex;
    justify-content: center;
    width: fit-content;
    padding: 0;
}
.dashboard-boxes {
    padding: 16px;
    min-height: 500px;
}

.dashboard-icon {
    > path {
        stroke: $dusk-lighter;
        fill: $dusk-lighter;
    }
}

.cohort-dashboard {
    position: relative;
}

.info-box-unit-count {
    gap: 0.5rem;

    .info-box-unit-count-link {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 5px;

        .link-icon path {
            fill: $lake;
        }
    }
}
