@import '../../styles/brand-colors';

.list-options {
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    margin-top: 3px;
    width: 200px;
    overflow-y: scroll;
    position: relative;
    height: 120px;

    .menu-selector-item {
        flex-direction: row !important;
        width: 100%;
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #b2c2c8;

        .image-list {
            margin-right: 1px;
            margin-top: 2px;
        }

        .text-list {
            padding-left: 10px;
            font-size: 12px;
            font-weight: 600;
            color: #2c3439;
            height: inherit;
            margin-top: 1px;
        }
    }
}
