@import '../../styles/brand-colors';

.pills-list {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    background: $white;
    border: 1px solid $dusk-lightest;
    border-radius: 4px 4px;
}
.pills-list-tags {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    padding-inline-start: 0;
    > li {
        list-style-type: none;
        margin: 4px;
    }
}
.pill {
}

.pills-list-input,
.pills-list-input:focus,
.pills-list-input:focus-visible {
    border: none;
    outline: none;
}
