@import '../../styles/brand-colors';

.rates-table {
    position: relative;
}

.tool-bar-icons {
    & > path {
        fill: $dusk-light;
    }

    &.disabled {
        cursor: not-allowed;

        > path {
            fill: $dusk-ten;
        }
    }

    &.undisplayed {
        display: none;
    }
}
.align-icon {
    margin-bottom: -3px;
    margin-left: 4px;
}
.actions-header {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $dusk-thirty;
    padding: 0 16px 8px;

    .alert-message {
        flex-grow: 1;
        margin: 0 16px 0 0;
    }
}

.factor-distribution-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    & .item {
        width: 22%;
    }
}

.red-alert-icon {
    margin-left: 5px !important;
}
.red-alert-icon path {
    fill: rgb(214, 46, 79) !important;
}

.blue-text {
    color: $lake;
}

.new-action-button {
    height: 24px;
    display: flex;
    margin-bottom: 7px;
    svg {
        transition: 0s;
    }
}

.action-button-group {
    display: none;
}

.new-action-button:hover > .action-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    .MuiChip-clickable {
        margin-left: 10px;
    }
}

.new-action-button:hover > svg {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}
