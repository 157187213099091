@import '../../styles/brand-colors';

.manage-unit-side-bar {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px 1px solid $dusk-thirty;
    border-left: none;
    box-shadow: -2px 1px 3px $dusk-thirty;
    border-radius: 3px 0 0;

    top: 0;
    right: 0;
    height: 100%;
    width: 25%;
    z-index: 200;
}

.manage-unit-side-bar-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;
    padding: 16px;
    margin-bottom: 16px;

    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}

.manage-unit-input {
    padding-left: 16px;

    .manage-unit-input-label {
        color: $dusk;
        margin-bottom: 8px;
    }

    .manage-unit-pill-list {
        min-height: 200px;
        max-height: 300px;
    }
}

.manage-unit-note {
    display: flex;
    flex-direction: row;
    padding: 8px;
    justify-content: center;
    align-items: flex-start;
    height: max-content;
    color: $dusk-lightest;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;

    > svg {
        margin-top: 2px;
        margin-right: 2px;
    }
}

.add-side-bar-apply-button {
    padding: 16px;
    border-top: 1px solid $dusk-thirty;

    & button {
        float: right;
        margin-top: 8px;
        padding-right: 24px;
        padding-left: 24px;

        @media screen and (max-width: 1500px) {
            width: 100%;
        }
    }
}
