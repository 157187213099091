@import '../../styles/brand-colors';

.switch-pillow {
    cursor: pointer;
    display: flex;
    height: 22px;
    font-size: 14px;

    .pill {
        margin-top: -3px;
    }

    .left {
        padding-left: 5px;
        padding-right: 3px;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
    }

    .right {
        padding-left: 3px;
        padding-right: 5px;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
    }

    .active {
        border: 1px solid $midnight-80;
        background-color: $midnight-80;
        color: #ffffff;
    }

    .pasive {
        border: 1px solid $midnight-10;
        background-color: $midnight-10;
        color: $dusk-lighter;
    }
}
