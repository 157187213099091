@import '../../styles/brand-colors';

.generic-input {
    color: $dusk-lightest;
    border: 1px solid $dusk-thirty;
    border-radius: 5px 5px;
    text-align: center;
    width: 100%;

    &:focus {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
    }

    &.error {
        border-color: $danger;
    }
    &::placeholder {
        color: $dusk-lightest;
    }
}

.changed {
    color: $dusk;

    &:disabled {
        color: $dusk-lightest;
    }
}
.multiple {
    height: 100px;
    resize: none;
    max-width: 100%;
}
