@import '../../styles/brand-colors';
.process-table {
    border: 1px solid $midnight-30;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;

    .virtualized-table-cell-div {
        display: flex;
    }
}

.statusSpan {
    width: 109px;
    height: 26px;
    left: 1354px;
    top: 316px;
    border-radius: 20px;
    color: #ffffff;
    text-transform: capitalize;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
    justify-content: center;

    &.processing {
        background-color: #b2c2c8;
    }

    &.success {
        background-color: #6ba342;
    }

    &.warning {
        background-color: #ddad49;
    }

    &.failed {
        background-color: #d62e4f;
    }
}

.processes-table-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: 1px solid $dusk-lightest;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px;
    align-items: center;
    div + div {
        margin-left: 10px;
    }
}

.processes-table-header-container {
    position: relative;
    margin-top: 32px;
}

.iconStatus {
    margin-left: 10px;
    margin-bottom: -7px;
    display: inline-flex;
}

.process-detail-table {
    padding-top: 16px;
    width: 80%;
    margin: 0 auto;

    .virtualized-table-cell-div {
        display: flex;
    }

    .virtualized-table {
        max-height: 285px;
    }
}

.modal-process-detail {
    height: 550px !important;
    min-width: 900px !important;

    .modal-loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 400px;
        width: 100%;
    }

    .footer-process-detail {
        width: 80%;
        margin: 0 auto;

        .info-footer {
            margin-left: 100px;
            width: 32.8%;
            float: left;
            height: 100px;
            display: flex;
            align-items: center;

            span {
                font-size: 16px;
                color: #707579;
                font-weight: bold;
            }
        }

        .info-footer-failed {
            width: 20%;
            float: left;

            span {
                font-size: 16px;
                color: #707579;
                font-weight: bold;
            }
        }

        .container-button-popup-process {
            width: 100%;
            height: 40px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;

            .button-disabled {
                border: 1px solid #999999;
                color: #999999;
            }

            .button-loading {
                display: flex;
                justify-content: center;

                .loader-dots {
                    position: relative;
                    top: 0;
                    left: 0;
                }
            }

            button {
                width: 201px;
                height: 38px;
                border-radius: 4px;
                padding: 8px 24px;
                font-size: 14px;
                line-height: 24px;
                color: #003349;
                background-color: #ffffff;
                font-weight: 600;
                border: 1px solid #4d7080;
                margin-left: 20px;
                outline: none;

                a {
                    color: #003349;
                    text-decoration: none !important;
                }
            }
        }

        .container-error-popup-process {
            float: right;
            width: 80%;
            height: 100px;

            textarea {
                box-sizing: border-box;
                padding: 5px 10px 5px 10px;
                width: 100%;
                height: 100%;
                border: 1px solid #b2c2c8;
                border-radius: 4px;
                background-color: #ffffff;
                resize: none;
            }
        }
    }

    .color-processing path {
        fill: #99adb6;
    }

    .color-success path {
        fill: #6ba342;
    }

    .color-warning path {
        fill: #ddad49;
    }

    .color-failed path {
        fill: #d62e4f;
        padding: 0.35em 3.15em !important;
    }
}

.container-icon {
    position: relative;
    max-height: 100px;
    padding-top: 10px;
    width: 80%;
    margin: 0 auto;

    span {
        font-family: Nunito Sans;
        size: 20px;
        color: #707579;
        font-weight: 700;
        margin-left: 30px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.container-status {
    padding-top: 5px;
    height: 30px;
    text-transform: capitalize;
    span {
        display: inline-block;
        min-width: 90px;
        padding: 4px 0;
    }
}

.process-table-row-changed-units {
    color: $dusk-light;
    border: 1px solid $midnight-20;
    border-radius: 4px 4px;
    background: $white;
    width: 90%;
    height: 40px;
    white-space: pre-wrap;
    overflow-y: auto;
    display: flex;
    justify-content: center;

    p {
        font-size: small;
        margin: 0;
    }
}
