@import '../../styles/brand-colors';

.change-log-table {
    display: flex;
    flex-direction: column;

    .change-log-table-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid $dusk-lighter;
        padding: 8px;

        > div {
            color: $midnight-90;
            font-weight: bold;
            flex: 1 1 0;
        }
    }

    .change-log-table-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        position: relative;
        font-size: 16px;
        color: $gray-two;
        height: 55px;

        &:before {
            content: '';
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0;
            height: 1px;
            margin: 0 auto;
            border-bottom: 1px solid $dusk-lightest;
        }

        > div {
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .highlighted {
        background-color: $midnight-10;
    }

    .change-resume-text-area {
        border: 1px solid $midnight-20;
        width: 100%;
        font-size: small;
        min-height: 50px;
        max-height: 50px;
        background-color: $white;
        resize: none;
    }
}

.change-log-change-resume,
.change-log-analyst-note {
    color: $dusk-light;
    border: 1px solid $midnight-20;
    border-radius: 4px 4px;
    background: $white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    font-size: small;
    svg {
        display: none;
    }
    div {
        opacity: initial;
    }
}

.change-log-loading {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.json-detail {
    display: flex;
    height: 100%;
    width: 100%;
    pre {
        width: 100%;
    }
}

.change-log-process-id-header {
    max-width: min-content;
    margin-left: 16px;
    display: flex;
    text-align: center;
    justify-content: center;
}
.change-log-process-id {
    max-width: 72px;
    margin-left: 16px;
    display: flex;
    text-align: center;
    justify-content: center;
}
