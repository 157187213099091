@import '../../styles/brand-colors';
.cohort-details-card {
    margin-top: 32px;
    border: 1px solid $dusk-thirty;
    border-radius: 4px;
    padding: 12px 16px 32px;
    width: 25%;
}
.factor-cohort-details {
    color: #4c5357;
    display: flex;
    flex-direction: column;

    > div {
        margin-top: 6px;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        font-family: Nunito Sans;
        line-height: 24px;
    }
    .datepicker-module_label__3Q8Rg {
        margin-top: 6px;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        font-family: Nunito Sans;
        line-height: 24px;
    }
}
.factor-values-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
}
