@import '../../styles/brand-colors';

.column-configuration {
    position: absolute;
    background-color: $white;
    top: -8px;
    right: 0;
    height: calc(100% + 8px);
    width: 25%;
    z-index: 200;
    border-left: 1px solid $dusk-thirty;
    box-shadow: -2px 1px 3px $dusk-thirty;
    border-radius: 0 3px 3px 0;
    display: flex;
    flex-direction: column;
}

.separator {
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 16px;
}

.column-configuration-top-bar {
    padding: 17px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;

    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    span {
        flex-grow: 1;
        text-align: center;
        justify-self: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}

.column-configuration-column-list {
    min-height: 100px;
    padding: 8px;

    .column-configuration-column-list-title {
        color: $dusk-light;
        font-weight: bold;
    }
}

.column-configuration-item {
    margin: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 4px;

    div {
        display: flex;
    }

    span {
        flex-grow: 1;
    }

    .add-column-icon,
    .remove-column-icon {
        height: 24px;
        width: 24px;
        margin: 0 auto;
    }

    .drag-column-icon {
        height: 12px;
        width: 12px;
        margin-right: 8px;
        cursor: grab;

        > path {
            fill: $dusk-light;
        }
    }

    .add-column-icon {
        > path {
            fill: $success;
        }
    }

    .remove-column-icon {
        > path {
            fill: $danger;
        }
    }
}

.column-configuration-save-action {
    display: flex;
    padding: 8px;
    border-top: 1px solid $dusk-thirty;
    justify-content: flex-end;

    & button {
        margin-top: 8px;
        padding-right: 24px;
        padding-left: 24px;

        @media screen and (max-width: 1500px) {
            width: 100%;
        }
    }
}

.column-configuration-reset-default-icon {
    height: 16px;
    width: 16px;
    svg {
        > path {
            fill: $dusk-lighter;
        }
    }
    margin-right: 8px;
    float: right;
    cursor: pointer;
}
