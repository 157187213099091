@import '../../styles/brand-colors';
.MuiListItem-root {
    text-transform: capitalize !important;
}
.editable-box {
    display: flex;
    flex-direction: column;
    background-color: $midnight-2;
    width: 240px;
    height: 190px;
    border: 1px solid $midnight-20;
    border-radius: 4px 4px;
}
.error {
    border: 1px solid red;
    border-radius: 4px 4px;
}

.editable-box-header {
    padding: 8px 16px;
    border-bottom: 1px solid $dusk-thirty;

    & .editable-box-title {
        float: left;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        color: $dusk-light;
    }

    & .editable-box-icon {
        float: right;
        > svg {
            transform: rotate(180deg);
            stroke: none;
            fill: none;

            > path {
                stroke: none;
                fill: $dusk-lightest;
            }
        }
    }
}

.editable-box-content {
    padding: 0 22px 0 19px;
    height: 100%;

    .for-text {
        padding: 45px 15px 0 30px;
    }

    .editable-box-input-father {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .editable-box-input {
            margin-top: 8px;

            & label {
                color: $dusk;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }

            & input {
                height: 28px;
                width: 199px;
            }
        }

        .editable-box-input-inline {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 8px;

            & label {
                flex: 1;
                color: $dusk;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }

            & input {
                flex: 1;
                height: 28px;
            }
        }

        .editable-box-input-subtext {
            text-align: center;
            width: 100%;
            margin: 8px;

            & .title {
                color: $dusk;
                font-weight: 600;
                font-size: 14px;
            }

            & .description {
                font-family: Wigrum, Helvetica, Arial, sans-serif;
                color: $dusk-lighter;
                font-weight: 700;
                font-size: 32px;
            }
        }
    }
    .editable-box-select {
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        text-transform: capitalize !important;
        input:nth-child(1) {
            background-color: #ffffff;
        }
    }

    .editable-box-menu {
        width: 201px;
        height: 122px;
    }

    .label-text {
        font-family: Nunito Sans;
        font-size: 16px;
    }
}
.editable-box-text-content {
    text-align: center;
    color: $dusk;
    font-size: 12px;
    font-family: Nunito Sans;
    margin-bottom: 5px;

    & a {
        color: $dusk;
        text-decoration: none !important;
    }
}
