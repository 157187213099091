@import '../../styles/brand-colors';

.cohort-creation-panel {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px solid $dusk-thirty;
    border-right: 1px solid $dusk-thirty;
    box-shadow: -2px 1px 3px $dusk-thirty;
    border-top: 1px solid $dusk-thirty;
    border-radius: 0 3px 0 0;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 99.7%;
    min-width: 17em;
    width: 30%;
    z-index: 200;
}

.cohort-creation-panel-title {
    display: flex;
    border-bottom: 1px solid $dusk-thirty;
    padding: 12px;
    margin-bottom: 16px;
    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}

.cohort-creation-panel-step {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        padding: 0 0 0 16px;
        color: $dusk-lighter;
        font-size: 16px;
    }
}

.cohort-panel-step-separator {
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 16px;
    bottom: 288px;
    position: absolute;
}

.components-separator {
    margin-top: 10px;
}

.cohort-creation-panel-value {
    margin-left: 27px;
    margin-right: 27px;
    margin-top: 15px;
    & input,
    Textarea {
        color: $dusk-lighter;
        border: 1px solid $dusk-thirty;
        border-radius: 5px;
        width: 100%;
        &:focus {
            outline: $midnight-30;
            border: 2px solid $midnight-30;
        }

        &.error {
            border-color: $danger;
        }
    }
    &.input {
        text-align: center;
        height: 30px;
        border: 0.5px solid #969a9c;
        box-sizing: border-box;
        border-radius: 4px;
    }

    & textarea {
        resize: none;
    }

    &.select-input {
        margin-right: 22px;
    }

    &.alert-message {
        float: none;
    }
}

.cohort-creation-panel-apply-button {
    padding-left: 16px;
    padding-right: 3px;
    padding-bottom: 300px;
    margin-top: 16px;

    & button {
        float: right;
        margin-top: 8px;
        padding-right: 24px;
        padding-left: 24px;
        margin-right: 16px;
    }
}

.monitor-icon-position {
    & svg {
        position: relative;
        top: 3px;
        margin-right: 15px;
        height: 18.33px;
        width: 20.17px;
    }
    & img {
        position: relative;
        top: 3px;
        margin-right: 15px;
        height: 18.33px;
        width: 20.17px;
    }
}

.not-available-button {
    opacity: 0.7;
}

.alert-message {
    flex-grow: 1;
    margin: 0 16px 0 0;
    padding: 6px 0px !important;
    & svg {
        margin-left: 16px !important;
    }
}

.cohort-name {
    color: $gray;
}

.options-select {
    margin-top: 10px;
}

.name-input {
    margin-top: 10px;
    height: 30px;
    .MuiOutlinedInput-input {
        padding: 6px 0 7px !important;
    }
}

.auto-complete-select {
    margin-top: 10px;
    & input,
    Textarea {
        border: none !important;
    }
}

.generic-label {
    color: $dusk-lighter;
    font-size: 16px;
    margin-top: 8px;
}
.checkbox-style {
    margin-left: 24px;
}
