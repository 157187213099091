@import '../../styles/brand-colors';

.info-box-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 10px;

    & > div {
        margin-right: 16px;
    }
}

.warning path {
    fill: rgb(255, 173, 2);
}
