@import '../../styles/brand-colors';
.cohort-table-header-container {
    position: relative;
}
.cohorts-table {
    border: 1px solid $midnight-30;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;
    .virtualized-table-header.filtered-action-header {
        color: #4c5357 !important;
        margin-left: 10px;
    }
}
.table-icons {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    & svg {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.cohorts-table-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: 1px solid $dusk-lightest;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px;
    margin-top: 4px;
    div + div {
        margin-left: 10px;
    }
}

.confirmation-modal {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .confirmation-content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        & svg {
            margin-bottom: 30px;
            > path {
                fill: #ddad49;
            }
        }
        .text-style {
            text-align: center;
            color: $dusk-light;
            font-size: 20px;
        }
    }
    .button-group {
        margin-top: 40px;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        .button-separation {
            margin-left: 10px;
        }
    }
}

.new-cohort-button {
    transition: 1s;
    height: 24px;
    display: flex;

    svg {
        transition: 0.3s;
    }

    .cohort-button-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
}

.rotate-button {
    transform: rotate(45deg);
}
