@import '../../styles/brand-colors';

.bulk-panel {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px solid $dusk-thirty;
    border-right: 1px solid $dusk-thirty;
    box-shadow: -2px 1px 3px $dusk-thirty;
    border-top: 1px solid $dusk-thirty;
    border-radius: 0 3px 0 0;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 99.7%;
    min-width: 17em;
    width: 30%;
    z-index: 200;
}

.bulk-panel-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;
    padding: 12px;
    margin-bottom: 16px;
    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}
.bulk-panel-options {
    flex-grow: 1;
}

.bulk-panel-step {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        padding: 0 0 0 16px;
        color: $dusk-lighter;
        font-size: 16px;
    }
}

.step-separator {
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 16px;
}

.bulk-panel-value {
    margin-left: 27px;
    margin-right: 27px;
    & input,
    Textarea {
        color: $dusk-lighter;
        border: 1px solid $dusk-thirty;
        border-radius: 5px 5px;
        width: 100%;
        &:focus {
            outline: $midnight-30;
            border: 2px solid $midnight-30;
        }

        &.error {
            border-color: $danger;
        }
    }
    &.input {
        text-align: center;
        height: 30px;
        border: 0.5px solid #969a9c;
        box-sizing: border-box;
        border-radius: 4px;
    }

    & textarea {
        resize: none;
    }

    &.select-input {
        margin-right: 22px;
    }
}

.bulk-panel-input-group {
    margin-bottom: 16px;

    & .bulk-panel-input-label {
        margin-left: 27px;
        margin-bottom: 5px;
        font-weight: bold;
        color: $dusk-lighter;
    }
}

.bulk-panel-apply-button {
    padding-left: 16px;
    padding-right: 3px;
    padding-bottom: 300px;
    border-top: 1px solid $dusk-thirty;

    & button {
        float: right;
        margin-top: 8px;
        padding-right: 24px;
        padding-left: 24px;
        margin-right: 16px;
    }
}

.bulk-panel-selector-button-div {
    display: flex;
    @media (max-width: 1800px) {
        flex-direction: column;
        align-items: center;
        .bulk-panel-selector-button-internal {
            align-items: flex-end;
            width: 230px;
            height: 30px;
            border-radius: 50px;
            border: 0.5px solid #969a9c;
            box-sizing: border-box;
            top: 10px;
            background: #fafbfb;
        }
        .bulk-panel-selector-button-drive {
            width: 230px;
            height: 30px;
            border-radius: 50px;
            border: 0.5px solid #969a9c;
            box-sizing: border-box;
            margin-top: 15px;
            background: #fafbfb;
            opacity: 0.7;
        }
    }
    @media (min-width: 1800px) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        right: 30px;
        .bulk-panel-selector-button-internal {
            align-items: flex-end;
            width: 230px;
            height: 30px;
            border-radius: 50px;
            border: 0.5px solid #969a9c;
            box-sizing: border-box;
            top: 10px;
            background: #fafbfb;
            margin-right: 20px;
        }
        .bulk-panel-selector-button-drive {
            width: 230px;
            height: 30px;
            border-radius: 50px;
            border: 0.5px solid #969a9c;
            box-sizing: border-box;
            margin-top: 15px;
            background: #fafbfb;
            opacity: 0.7;
            margin-left: 20px;
        }
    }
}

.monitor-icon-position {
    & svg {
        position: relative;
        top: 3px;
        margin-right: 15px;
        height: 18.33px;
        width: 20.17px;
    }
    & img {
        position: relative;
        top: 3px;
        margin-right: 15px;
        height: 18.33px;
        width: 20.17px;
    }
}
.bulk-panel-close-file-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & input {
        text-align: center;
        height: 35.25px;
        border: 0.5px solid #969a9c;
        box-sizing: border-box;
        border-radius: 4px;
        width: 90%;
        margin-left: 27px;
    }
    & svg {
        width: 25px;
        margin-right: 27px;
        margin-top: 3px;
        > path {
            fill: $sunset;
            opacity: 0.7;
        }
    }
}
.bulk_file_type_display {
    display: flex;
    flex-direction: column;
    min-height: 0;
    align-items: flex-end;
    margin-top: 15px;
    margin-right: 20px;
}
.bulk-panel-selector-button-internal {
    align-items: flex-end;
    width: 230px;
    height: 30px;
    border-radius: 50px;
    border: 0.5px solid #969a9c;
    box-sizing: border-box;
    background: #fafbfb;
}

.not-available-button {
    opacity: 0.7;
}
