.change-summary {
    display: flex;
    opacity: 0.3;
    word-break: break-word;
    flex-wrap: wrap;
    width: 100%;

    .change-summary-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 4px;
        padding-right: 4px;
        width: 100%;

        .change-summary-item-icon {
            transform: rotate(180deg);
            margin-right: 8px;
        }

        .change-summary-item-text {
            width: 100%;

            .filter-criteria {
                text-decoration: underline;
                margin-left: -8px;
            }
            ul {
                margin-inline-start: 0;
                margin-inline-end: 0;
                margin-block-start: 0;
                margin-block-end: 0;
                padding-inline-start: 0;
                list-style-type: none;
            }
        }
    }
}
