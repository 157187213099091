@import '../../styles/brand-colors';

.set-side-bar {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px solid $dusk-thirty;
    border-left: none;
    box-shadow: -2px 1px 3px $dusk-thirty;
    top: -8px;
    border-radius: 0 3px 3px 0;
    right: 0;
    min-width: 30%;
    max-width: 65%;
    height: calc(100% + 8px);
    z-index: 100;
    overflow: scroll;

    div:nth-last-child(2) {
        padding-bottom: 0;
    }
}

.set-side-bar-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;
    padding: 17px;

    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}

.set-side-bar-step {
    padding-left: 10px;
    padding-bottom: 10px;
    margin-top: -5px;

    p {
        color: $dusk-lighter;
        font-size: 16px;
    }
}

.step-separator {
    color: $dusk-thirty;
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 3px 16px 3px 16px !important;
}

.set-side-bar-value {
    margin-left: 27px;
    margin-right: 27px;

    & input {
        color: $dusk-lighter;
        border: 1px solid $dusk-thirty;
        border-radius: 5px 5px;
        text-align: center;
        width: 100%;

        &:focus {
            outline: $midnight-30;
            border: 2px solid $midnight-30;
        }
    }

    .error-input {
        border-color: #d62e4f !important;
    }

    span {
        display: flex;
        font-size: 11px;
        color: #d62e4f;
        opacity: 0.8;
    }
}

.set-side-bar-input-group {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -5px;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: $secondary-70 !important;
}

.set-side-bar-radio-group-label {
    color: $dusk-light;
    font-weight: bolder;
    font-size: 14px;
}

.set-to-period-options {
    margin-left: 27px;
    margin-right: 27px;
    margin-bottom: 8px;

    .period-dow-filter-select {
        margin-top: 8px;
    }
}

.set-to-holiday-options {
    margin-left: 27px;
    margin-right: 27px;
    margin-bottom: 8px;

    .holiday-dow-filter-select {
        margin-top: 8px;
    }
}

.set-side-bar-set-button {
    padding: 5px 10px;
    border-top: 1px solid $dusk-thirty;

    & button {
        float: right;
        margin-left: 8px;
        padding-right: 24px;
        padding-left: 24px;
        margin-top: -2.5px;
        margin-bottom: 2.5px;
    }
}

.set-side-bar-radio {
    color: $midnight-40 !important;

    &:hover {
        background-color: $midnight-0 !important;
    }

    &.MuiCheckbox-colorSecondary.Mui-checked {
        color: $lake !important;
        padding-right: 0;
    }
}
