@import '../../styles/brand-colors';

.success path {
    fill: rgb(0, 164, 51) !important;
}

.danger path {
    fill: rgb(189, 0, 36) !important;
}

.icons-tool-bar {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $midnight-30;
    margin-bottom: 16px;
}

.unit-selector {
    position: relative;
    padding: 32px;
    border: 1px solid $midnight-30;
    border-radius: 4px;
    min-height: 400px;
}
.custom-table-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 12px;
    margin-right: -16px;
}
.no-units-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 500px;
    }

    p {
        font-weight: bold;
        font-size: 16px;
    }
}
