@import '../../styles/brand-colors';
.grid {
    display: grid;
    grid-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: auto;
    height: 100%;
}
.item {
    display: flex;
    justify-content: center;
    width: fit-content;
    padding: 0;
}

.dashboard-boxes {
    padding: 16px;
    min-height: 500px;
}

.make-draggable {
    border: 4px solid $midnight-60;
    border-radius: 2px;

    .grid {
        display: contents;
    }
}

.dashboard-icon {
    > path {
        stroke: $dusk-lighter;
        fill: $dusk-lighter;
    }
}

.download-restrictions-icon {
    > path {
        stroke: none;
        fill: $dusk-lighter;
    }
}

.unit-dashboard-preferences-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .save-button-container {
        display: flex;
        justify-content: space-between;

        .save-button {
            position: inherit;
            > path {
                fill: $success;
            }
        }

        .save-text {
            font-weight: bold;
        }
    }
}

.unit-dashboard {
    position: relative;
}
