@import '../../styles/brand-colors';

.custom-cohort-view {
    min-height: 800px;
    display: flex;
    flex-direction: column;
}

.save-custom-cohort-button {
    float: right;
}

.custom-cohort-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid $dusk-thirty;

    h5 {
        font-size: 20px;
    }

    svg {
        margin-left: 8px;
        margin-right: 8px;

        & > path {
            fill: $dusk-lightest;
        }
    }
}

.custom-cohort-alert {
    > div {
        float: none;
        width: inherit;
    }
}

.custom-cohort-loading {
    min-height: 400px;
}

.custom-cohort-error {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    svg {
        max-width: 600px;
    }
}

.custom-cohort-content {
    flex-grow: 1;
    padding-right: 32px;
    padding-left: 32px;
    padding-top: 16px;

    .custom-cohort-name {
        display: flex;
        flex-direction: row;
        align-items: center;

        :first-child {
            margin-right: 8px;
            font-weight: bolder;
        }
        span {
            color: $dusk-light;
            font-size: 14px;
        }

        .custom-cohort-name-input {
            width: 240px;
        }

        @media screen and (max-width: 1200px) {
            .custom-cohort-name-input {
                flex-grow: 1;
            }
        }
    }

    .save-wrapper {
        position: relative;
    }

    .custom-cohort-tabs {
        margin-top: 8px;
        flex-grow: 1;
        height: 100%;
    }
}
