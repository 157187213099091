@import '../../styles/brand-colors';

.units-view {
    padding: 8px 32px 32px;
    position: relative;
}

.main-loading-spinner {
    height: 800px;
}

.chart-loading-spinner {
    height: 300px;
}

.upper-loading-spinner {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 100px;
}
