@import '../../styles/brand-colors';
.cohort-factor-table {
    padding: 8px;
    min-height: 400px;
    position: relative;
    border: 1px solid $dusk-thirty;
    border-radius: 4px 4px;

    .number-input {
        width: 90%;
    }
}

.cohort-factors-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.no-factors-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $dusk-thirty;
    border-radius: 4px 4px;
    padding: 8px;

    svg {
        max-width: 500px;
    }

    p {
        font-weight: bold;
        font-size: 16px;
    }
}
