@import '../../styles/brand-colors';

.icon-tool-bar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    & > div {
        margin-right: 16px;
    }
}
