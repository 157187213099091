@import '../../styles/brand-colors';

.info-box {
    display: flex;
    flex-direction: column;
    background-color: $midnight-2;
    width: 240px;
    height: 190px;
    border: 1px solid $midnight-20;
    border-radius: 4px 4px;

    .flag {
        z-index: 10 !important;
        color: white;
        position: absolute;
        margin-top: -5px;
        margin-left: 190px;

        .flag-content {
            padding-top: 5px;
            width: 40px;
            height: 40px;
            background: $clay;
            border-radius: 5px 5px 5px 5px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            font-size: 14px;
            margin-bottom: -4px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
            svg path {
                fill: white;
            }
        }

        .flag-content:after {
            background: $clay;
            content: '';
            position: absolute;
            bottom: -20px;
            left: -20px;
            right: -20px;
        }
        .flag-bottom {
            width: 40px;
            height: 30px;
            position: relative;
            overflow: hidden;
        }
        .flag-bottom:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 30px;
            background: $clay;
            margin-left: 5px;
            margin-top: -15px;
            transform: rotate(45deg); /* Prefixes... */
            box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
            border-radius: 4px 4px 4px 4px;
        }
    }
}

.info-box-header {
    padding: 8px 16px;
    border-bottom: 1px solid $dusk-thirty;

    & .info-box-label {
        float: left;
        font-size: 14px;
        line-height: 27px;
        font-weight: 600;
        color: $dusk-light;
    }

    & .info-box-icon {
        float: right;
        > svg {
            transform: rotate(180deg);
            stroke: none;
            fill: none;

            > path {
                stroke: none;
                fill: $dusk-lightest;
            }
        }
    }
}

.info-box-content {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &.info-box-content-vertical {
        flex-direction: column-reverse;

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
            font-size: 16px;
            font-family: Wigrum;
            font-weight: bold;
        }
        > div {
            flex-direction: column-reverse;
            span {
                font-family: Nunito Sans;
                font-size: 12px;
                font-weight: lighter;
            }
            &:after {
                bottom: 0;
                top: 0px;
                left: 24px;
                right: 24px;
                border-right: none;
                border-top: 1px solid $midnight-20;
            }
        }
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        color: $dusk-lighter;
        margin: 0;
    }
    span {
        &.center {
            text-align: center;
        }
        &.light-gray {
            color: $dusk-lightest;
        }
    }

    > div {
        display: flex;

        position: relative;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        flex-direction: column;

        span {
            font-weight: bold;
        }

        &:after {
            content: '';
            top: 24px;
            bottom: 24px;
            right: 0;
            position: absolute;
            border-right: 1px solid $midnight-20;
        }

        &:last-child:after {
            display: none;
        }
    }
}
.info-box-subtitle {
    text-align: center;
    color: $dusk-lightest;
    font-size: 12px;
    font-family: Nunito Sans;

    & a {
        color: $dusk-lightest;
    }
}

.large-boxes-text-size {
    font-size: 25px;
    text-align: center;
}

.rooms {
    justify-content: initial !important;
    flex-direction: row !important;

    .room-label,
    .rooms-label {
        position: relative;
        padding-left: 15px;
        display: flex;

        .image-room {
            position: relative;
            margin-right: 15px;
            padding-top: 3px;
        }

        .text-image {
            position: relative;
            font-size: 12px;
        }
    }

    .bedroom {
        text-align: center;
        font-size: 22px;
        padding-left: 60px;
    }

    .bathroom {
        text-align: center;
        font-size: 22px;
        padding-left: 30px;
        padding-top: 20px;
    }

    .text-for-number {
        font-weight: bolder;
        top: calc(50% - 24px / 2 + 468px);
        color: #4c5357;
    }
}

.button-modal-note {
    border-radius: 25px;
    width: 180px;
    height: 100px;
    font-size: 16px;
    margin-left: 50%;
    font-weight: 900;
    transform: translateX(-50%);
    color: #335c6d;
    background-color: #ffffff;
    border-color: #335c6d;
    outline: none;
    border-width: thin;
}

.icon-button-modal {
    margin-bottom: -4px;
}

.info-details-modal {
    display: block;
    color: #707579;
    font-size: 17px;
    font-weight: 900;
    font-family: Nunito Sans, Helvetica, Arial;
    padding: 10px;
}

.info-note-modal {
    font-size: 16px;
    display: block;
    color: #969a9c;
    padding: 0px 20px 0px 20px;
}

.modal-module_modalWindow__1kWze {
    z-index: 1;
}
